import React, { useContext, useRef, useEffect, useState } from 'react'
import { Box, Flex, Image, Text } from 'rebass'
import { UneeqContext, useIsSmallScreen, useUneeqState } from 'uneeq-react-core'
import { Button } from 'rebass'
import {
  Overlay,
  PermissionsPrompt,
  PermissionsRejected,
  AvatarUnavailable
} from 'uneeq-react-ui'
import styles from './styles'
import { motion, AnimatePresence } from 'framer-motion'
import LoadingVideo from '../LoadingVideo/LoadingVideo'

const LoaderVideo = ({ loaded, done, isSmallScreen, widgetMode }: any) => {
  const buttonStyle = isSmallScreen
    ? {
        position: 'absolute',
        zIndex: 5,
        top: 10,
        right: 10,
        bottom: 'auto'
      }
    : {
        position: 'absolute',
        right: 15,
        bottom: 15,
        zIndex: 5,
        top: 'auto'
      }

  return (
    <Box sx={widgetMode ? styles.loaderVideoWidget : styles.loaderVideo}>
      <LoadingVideo onFinishVideo={done} widgetMode={widgetMode} />
      {loaded ? (
        <Button onClick={done} sx={buttonStyle}>
          Skip
        </Button>
      ) : (
        <Button disabled sx={buttonStyle}>
          Loading...
        </Button>
      )}
    </Box>
  )
}

const MotionText = motion.custom(Text)
export interface LoadingTip {
  title: string
  videoWebm?: any
  videoMP4?: any
  img?: any
  showOnDesktop: boolean
  showOnMobile: boolean
}
interface LoadingProps {
  loadingTips: Array<LoadingTip>
  isSmallScreen: boolean
}

export const Loading: React.FC<LoadingProps> = ({
  loadingTips,
  isSmallScreen
}) => {
  const sources = document.querySelectorAll('source')
  let checkedLoadingTips = loadingTips
  let source_errors = 0
  const addedFallback = useRef(false)
  for (let i = 0; i < sources.length; i++) {
    // eslint-disable-next-line no-loop-func
    sources[i].addEventListener('error', () => {
      source_errors++
      if (source_errors >= sources.length) fallBack()
    })
  }
  function fallBack() {
    if (addedFallback.current) return
    addedFallback.current = true
    const videoContainer = document.getElementById('videoContainer')
    const video = videoContainer!.querySelector('video') as HTMLVideoElement
    if (video) {
      video.parentElement!.removeChild(video)
    }
    const img = document.createElement('img')
    img.setAttribute('src', currentLoadingTip?.img)
    img.setAttribute('alt', currentLoadingTip?.title)
    img.setAttribute('width', '350px')
    videoContainer!.insertBefore(img, videoContainer!.firstChild)
  }
  const { loadingPercentage } = useUneeqState()

  if (isSmallScreen) {
    checkedLoadingTips = checkedLoadingTips.filter(tip => tip.showOnMobile)
  }

  const [currentLoadingTip, setCurrentLoadingTip] = useState(
    checkedLoadingTips?.[0]
  )
  const [showLoadingTipText, setShowLoadingTipText] = useState(true)

  useEffect(() => {
    if (checkedLoadingTips.length > 0) {
      const changeLoadingTip = () => {
        setShowLoadingTipText(false)

        // When the exit animation finishes we show the new tip.
        setTimeout(() => {
          const currIdx = checkedLoadingTips.findIndex(
            lt => lt?.title === currentLoadingTip?.title
          )
          if (currIdx === checkedLoadingTips.length - 1) {
            setCurrentLoadingTip(checkedLoadingTips[0])
          } else {
            setCurrentLoadingTip(checkedLoadingTips[currIdx + 1])
          }
          setShowLoadingTipText(true)
        }, 600)
      }

      const timeout = setTimeout(changeLoadingTip, 1800)

      return () => clearTimeout(timeout)
    }
  }, [checkedLoadingTips, currentLoadingTip])

  return (
    <Overlay sx={{ backgroundColor: 'black' }}>
      <Flex sx={styles.loading.container}>
        <Flex
          sx={styles.loading.videoContainer}
          id="videoContainer"
          data-testid="videoContainer"
        >
          {currentLoadingTip?.videoMP4 && currentLoadingTip?.videoWebm && (
            <video
              autoPlay={true}
              loop={true}
              playsInline={true}
              controls={false}
              muted={true}
            >
              <source src={currentLoadingTip?.videoWebm} type="video/webm" />
              <source src={currentLoadingTip?.videoMP4} type="video/mp4" />
              {currentLoadingTip?.img && (
                <Image
                  sx={{ label: 'loadingImg', width: 350 }}
                  src={currentLoadingTip?.img}
                  alt={currentLoadingTip?.img}
                />
              )}
            </video>
          )}
          {checkedLoadingTips.length > 0 && (
            <AnimatePresence>
              {showLoadingTipText && (
                <MotionText
                  variants={{
                    start: {
                      opacity: 0,
                      transform: 'rotateX(-100deg)',
                      transformOrigin: 'top'
                      // transition: {duration: 0.6, ease: 'easeIn'},
                    },
                    animate: {
                      opacity: 1,
                      transform: 'rotateX(0deg)',
                      transformOrigin: 'top'
                    },
                    exit: {
                      opacity: 0,
                      transform: 'rotateX(100deg)',
                      transformOrigin: 'bottom',
                      transition: { duration: 0.6, ease: 'easeOut' }
                    }
                  }}
                  initial="start"
                  animate="animate"
                  exit="exit"
                  transition={{ duration: 0.6, ease: 'easeIn' }}
                  key={currentLoadingTip?.title}
                  sx={styles.loading.message}
                >
                  {currentLoadingTip?.title}
                </MotionText>
              )}
            </AnimatePresence>
          )}
          <Flex sx={styles.loading.barContainer}>
            <Flex sx={styles.loading.barInnerContainer}>
              <Flex
                sx={{
                  ...styles.loading.bar,
                  width: `${loadingPercentage * 5}px`
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Overlay>
  )
}

const LoadingScreen = React.memo((props: any) => <LoaderVideo {...props} />)
interface GatewayProps {
  restart: () => void
  children: any
  video?: any
  widgetMode?: boolean
  showLoadingVideo: boolean
}
const Gateway: React.FC<GatewayProps> = ({
  restart,
  children,
  video,
  widgetMode = false,
  showLoadingVideo
}) => {
  const { ready, unavailable, permissionAllowed } = useUneeqState()
  const { config, playDHVideo, repeatLastQuestion, dispatch } = useContext(
    UneeqContext
  )
  const isSmallScreen = useIsSmallScreen()
  const [showLoading, setShowLoading] = useState(showLoadingVideo)

  useEffect(() => {
    dispatch({ type: 'loadingVideoActive' })
  }, [dispatch])

  useEffect(() => {
    if (!showLoadingVideo) {
      dispatch({ type: 'loadingVideoFinished' })
    }
  }, [dispatch, showLoadingVideo])

  const handleDoneVideo = () => {
    setShowLoading(false)
    dispatch({ type: 'loadingVideoFinished' })

    // iOS devices pauses DH video while introduction is being reproduced.
    // We have to resume as soon as the introduction video finishes
    playDHVideo()
    repeatLastQuestion()
  }

  if (unavailable) return <AvatarUnavailable restart={restart} />

  if (config.sendLocalVideo || config.sendLocalAudio) {
    return permissionAllowed === null ? (
      <PermissionsPrompt video={video} />
    ) : permissionAllowed === false ? (
      <PermissionsRejected restart={restart} />
    ) : permissionAllowed && ready && !showLoading ? (
      children
    ) : (
      // UneeQ is not ready or
      // Permissions are completely unknown
      // They could be aproved, rejected or neither
      // Show loader for now..
      <LoadingScreen
        isSmallScreen={isSmallScreen}
        loaded={permissionAllowed && ready}
        done={handleDoneVideo}
        widgetMode={widgetMode}
      />
    )
  } else {
    return ready && !showLoading ? (
      children
    ) : (
      <LoadingScreen
        isSmallScreen={isSmallScreen}
        loaded={ready}
        done={handleDoneVideo}
        widgetMode={widgetMode}
      />
    )
  }
}

export default Gateway
