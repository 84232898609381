import React from 'react'
import { useUneeqState } from 'uneeq-react-core'
import TestButtons from '../../TestButtons'

const UIToolbar = () => {
  const { restartAfterStatement } = useUneeqState()

  // We should hide the UI
  if (restartAfterStatement) return null

  return <>{process.env.NODE_ENV !== 'production' && <TestButtons />}</>
}

export default UIToolbar
