import React from 'react'
import { Flex, SxStyleProp } from 'rebass'
import styles from '../styles'
import { ReactComponent as PhoneIcon } from '../icons/phone.svg'

interface CellPhoneButtonProps {
  onClick: () => void
  sx?: SxStyleProp
}
const CellPhoneButton: React.FC<CellPhoneButtonProps> = ({
  onClick,
  sx = {}
}) => {
  return (
    <Flex sx={{ ...styles.phoneButton, ...sx }} onClick={onClick}>
      <PhoneIcon />
    </Flex>
  )
}

export default CellPhoneButton
