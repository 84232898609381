import React from 'react'
import { Flex, Heading } from 'rebass'
import { useUneeqState } from 'uneeq-react-core'
import { useTranslation } from 'react-i18next'
import useWidgetContext from '../../app/hooks/useWidgetContext'
import { MayaOverlay } from '../MayaOverlay'
import styles from './styles'

const MayaExitSession = () => {
  const { exitSession } = useUneeqState()
  const { widgetMode } = useWidgetContext()
  const { t } = useTranslation()

  if (!exitSession || widgetMode) return null

  return (
    <MayaOverlay>
      <Flex sx={styles.container}>
        <Heading sx={styles.heading}>{t('ExitSession.title')}</Heading>
      </Flex>
    </MayaOverlay>
  )
}

export default MayaExitSession
