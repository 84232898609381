const styles = {
  grid: {
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
    width: ['100%', '100%', '100%', '100%', '560px', '560px'],
    margin: '0 auto',
    height: '100%',
    overflow: 'hidden',
    zIndex: 10,
    py: [0, 0, 0, 0, 5, 5]
  },
  header: {
    display: 'flex',
    gridRow: '1 / 2',
    gridColumn: '1 / -1',
    alignSelf: 'flex-start',
    zIndex: 10,
    bg: 'backgroundPrimarySolid',
    color: 'text',
    alignItems: 'center',
    justifyContent: 'space-between',
    p: [6, 6, 6, 6, 10, 10],
    borderRadius: [
      'unset',
      'unset',
      'unset',
      'unset',
      '8px 8px 0px 0px',
      '8px 8px 0px 0px'
    ]
  },
  headerInfo: {
    fontSize: ['16px', '16px', '24px', '24px', '24px', '24px'],
    lineHeight: ['20px', '20px', '32px', '32px', '32px', '32px'],
    letterSpacing: '-0.01em',
    '& > *:not(:last-child)': {
      mr: [6, 6, 6, 6, 10, 10]
    }
  },
  headerLocation: {
    display: 'flex',
    padding: '3px',
    bg: '#464646',
    borderRadius: 'card',
    '& svg': { width: '16px', height: '16px' }
  },
  radioButtonLabel: { py: 2, px: 3 },
  radioButtonLabelSelected: { bg: 'informationDark', borderRadius: 'card' },
  container: {
    margin: 'auto',
    gridRow: '2 / -1',
    gridColumn: '1 / -1',
    width: '100%',
    height: '100%'
  },
  overlay: {
    overflow: 'hidden',
    height: ['auto', 'auto', 'auto', 'auto', '250px', '250px'],
    display: 'flex',
    flexDirection: ['row', 'row', 'row', 'row', 'column', 'column'],
    mx: '6px'
  },
  imageContainer: {
    display: ['none', 'none', 'none', 'none', 'flex', 'flex'],
    flexGrow: 1,
    overflow: 'hidden',
    '& > img': {
      objectFit: 'cover',
      objectPosition: 'center',
      width: '100%',
      height: '100%'
    }
  },
  hospitalsList: {
    gridRow: '2 / -1',
    gridColumn: '1 / -1',
    // justifySelf: 'center',
    alignSelf: 'flex-end',
    zIndex: 10,
    mb: [6, 6, 6, 6, 10, 10]
  },
  hospitalDescription: {
    color: 'text',
    textAlign: 'start',
    pr: 1,
    '& > *:not(:last-child)': {
      mb: 1
    }
  },
  hospitalContainer: {
    p: 6,
    flexDirection: 'row',
    display: ['flex', 'flex', 'flex', 'flex', 'block', 'block'],
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    bg: 'backgroundPrimarySolid',
    borderRadius: '8px'
  },
  hospitalName: {
    fontSize: 2,
    lineHeight: '19px'
  },
  hospitalInfo: {
    fontSize: 0,
    lineHeight: '17px',
    opacity: 0.7
  },
  phone: {
    display: ['none', 'none', 'none', 'none', 'inline-block', 'inline-block']
  },
  phoneButton: {
    stroke: 'text',
    width: 'auto',
    p: 2,
    justifyContent: 'center',
    alignItems: 'center',
    display: ['flex', 'flex', 'flex', 'flex', 'none', 'none'],
    backgroundColor: 'backgroundSecondary',
    borderRadius: 'buttonBig',
    cursor: 'pointer',
    minWidth: 'unset',
    transition: 'all .2s',
    '& svg': {
      minWidth: 16,
      height: 16,
      margin: 0
    },
    '&:hover': {
      backgroundColor: 'backgroundSecondaryHover'
    }
  },
  hospitalRating: {
    display: 'inline-block'
  },
  hospitalRatingInfo: {
    mr: 3
  },
  hospitalRatingStars: {
    color: '#FDBA18',
    '& > *:not(:last-child)': {
      mr: 1
    }
  }
}

export default styles
