import { useUneeqState, UneeqContext } from 'uneeq-react-core'
import { useEffect, useContext } from 'react'

const useAnswerQuestionTimeout = (timeout: number) => {
  const { questionLoading } = useUneeqState()
  const { dispatch } = useContext(UneeqContext)

  useEffect(() => {
    if (questionLoading) {
      // The user send the response
      const clearLoadingIndicatorTimer = setTimeout(() => {
        dispatch({
          type: 'mayaError',
          payload: { message: "We didn't receive an answer. Please try again" }
        })
      }, timeout)

      return () => clearTimeout(clearLoadingIndicatorTimer)
    }
  }, [questionLoading, dispatch, timeout])
}

export default useAnswerQuestionTimeout
