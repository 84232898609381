import { stripSsml, UneeqContext, useUneeqState } from 'uneeq-react-core'
import { Button, Flex, Text, Box } from 'rebass'
import styles from '../styles'
import React, { useContext, useEffect, useState, useMemo } from 'react'
import {
  useCombobox,
  UseComboboxState,
  UseComboboxStateChangeOptions
} from 'downshift'
import { sendMessage } from '../../../socket'
import { Input } from '@rebass/forms'
import exitSvg from '../exit.svg'
import { ReactComponent as ExitIcon } from '../exit.svg'
import { useTranslation } from 'react-i18next'

function stateReducer(
  state: UseComboboxState<any>,
  actionAndChanges: UseComboboxStateChangeOptions<any>
) {
  const { type, changes } = actionAndChanges
  switch (type) {
    case useCombobox.stateChangeTypes.InputChange:
      return {
        ...changes,
        selectedItem: null
      }
    case useCombobox.stateChangeTypes.InputKeyDownEscape:
      return {
        ...changes,
        selectedItem: null,
        inputValue: ''
      }
    default:
      return changes
  }
}

const AutocompleteQuestion = () => {
  const {
    mayaQuestion,
    autocompleteOptions,
    hideQuestionTitle
  } = useUneeqState()
  const { dispatch } = useContext(UneeqContext)
  const { t } = useTranslation()
  const questionText = useMemo(() => stripSsml(mayaQuestion.question), [
    mayaQuestion
  ])
  const [inputItems, setInputItems] = useState(autocompleteOptions || [])

  const submitOption = (selectedItem?: { id: string; label: string }) => {
    const info = {
      type: 'response',
      questionId: mayaQuestion.id,
      response: selectedItem?.id ? selectedItem.id.toString() : '',
      label: selectedItem?.label
        ? selectedItem.label
        : t('Transcript.skippedQuestion')
    }
    dispatch({ type: 'mayaMessage', payload: info })
    sendMessage(info)
  }

  useEffect(() => {
    autocompleteOptions && setInputItems(autocompleteOptions)
  }, [autocompleteOptions])

  const {
    isOpen,
    selectedItem,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    reset
  } = useCombobox({
    items: inputItems,
    stateReducer,
    itemToString: (item: any) => item.label,
    onInputValueChange: ({ inputValue }) => {
      sendMessage({
        type: 'autocomplete',
        questionId: mayaQuestion.id,
        input: inputValue
      })
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem && mayaQuestion.autoSubmit) {
        submitOption(selectedItem)
      }
    }
  })

  const showSkipButton = mayaQuestion.optional && !selectedItem

  return (
    <Flex sx={styles.questionContainer}>
      <Flex sx={styles.topContainer}>
        {!hideQuestionTitle && <Text sx={styles.question}>{questionText}</Text>}
        <Text sx={styles.instructions}>{mayaQuestion.instruction}</Text>
      </Flex>
      <Flex sx={styles.autocompleteContainer}>
        <ul {...getMenuProps()} style={styles.autocompleteMenu}>
          {inputItems.length > 0 &&
            inputItems.map((item: any, index: any) => (
              <Box
                as="li"
                key={`${item.label}${index}`}
                {...getItemProps({ item, index })}
                sx={{ ...styles.selectButton, ...styles.autocompleteItem }}
              >
                {item.label}
              </Box>
            ))}
        </ul>
        <Flex
          {...getComboboxProps()}
          sx={{ ...styles.autocompleteCombobox, width: '100%' }}
        >
          <Flex sx={{ ...styles.autocompleteInputContainer }}>
            <Input
              {...getInputProps()}
              placeholder={mayaQuestion.placeholder || 'Type your answer here'}
              autoFocus
              sx={{
                ...styles.textInput
              }}
            />
            <Box onClick={reset} sx={styles.autocompleteInputClearButton}>
              <ExitIcon />
            </Box>
          </Flex>
          {!mayaQuestion.autoSubmit && !showSkipButton && (
            <Button
              disabled={!selectedItem}
              type="submit"
              onClick={() => submitOption(selectedItem)}
              sx={styles.button}
            >
              {t('Question.submit')}
            </Button>
          )}
          {showSkipButton && (
            <Button
              type="submit"
              onClick={() => submitOption()}
              sx={styles.button}
            >
              {t('Question.skip')}
            </Button>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default AutocompleteQuestion
