import React, { useContext, useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { stripSsml, UneeqContext, useUneeqState } from 'uneeq-react-core'
import { sendMessage } from '../../../socket'
import { Button, Flex, Text, Box } from 'rebass'
import { Select, Input } from '@rebass/forms'
import styles from '../styles'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { DateUtils } from 'react-day-picker'
import { isIOS } from 'react-device-detect'
import 'react-day-picker/lib/style.css'

import dateFnsFormat from 'date-fns/format'
import dateFnsParse from 'date-fns/parse'

const parseDate = (str: string, format: string) => {
  const parsed = dateFnsParse(str, format, new Date())
  if (DateUtils.isDate(parsed)) {
    return parsed
  }
  return undefined
}

const formatDate = (date: Date, format: string) => {
  return dateFnsFormat(date, format)
}

const DatePickerOverlay = ({
  classNames,
  selectedDay,
  children,
  ...props
}: any) => {
  return (
    <Box className={classNames.overlayWrapper} {...props}>
      <Box
        sx={{
          bg: 'backgroundPrimary',
          color: 'text'
        }}
        className={classNames.overlay}
        style={{ bottom: 30 }}
      >
        {children}
      </Box>
    </Box>
  )
}

const fromMonth = new Date(1910, 0)
const toMonth = new Date()

interface YearMonthFormProps {
  date: Date
  localeUtils: any
  onChange: Function
}
const YearMonthForm = ({ date, localeUtils, onChange }: YearMonthFormProps) => {
  const months = localeUtils.getMonths()

  const years = []
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i)
  }

  const handleChange = (e: any) => {
    const { year, month } = e.target.form
    onChange(new Date(year.value, month.value))
  }

  return (
    <Box className="DayPicker-Caption">
      <form>
        <Flex sx={styles.datePickerForm}>
          <Select
            sx={styles.datePickerSelect}
            name="month"
            onChange={handleChange}
            value={date.getMonth()}
          >
            {months.map((month: string, i: number) => (
              <Text
                as="option"
                sx={{
                  bg: 'backgroundPrimary',
                  text: 'color',
                  fontFamily: 'inherit'
                }}
                key={month}
                value={i}
              >
                {month}
              </Text>
            ))}
          </Select>
          <Select
            sx={styles.datePickerSelect}
            name="year"
            onChange={handleChange}
            value={date.getFullYear()}
          >
            {years.map(year => (
              <Text
                as="option"
                sx={{
                  bg: 'backgroundPrimary',
                  text: 'color',
                  fontFamily: 'inherit'
                }}
                key={year}
                value={year}
              >
                {year}
              </Text>
            ))}
          </Select>
        </Flex>
      </form>
    </Box>
  )
}

const getFormatedDate = (date: Date | string) => {
  return isIOS
    ? getDateFromGenericFormat(date as string)
    : formatDate(date as Date, FORMAT)
}

const getDateFromGenericFormat = (date: string) => {
  // Generic format is like YYYY-MM-DD
  const [year, month, day] = date.split('-')

  return `${month}/${day}/${year}`
}
const FORMAT = 'MM/dd/yyyy'

const DateQuestion = () => {
  const { dispatch } = useContext(UneeqContext)
  const { t } = useTranslation()
  const { mayaQuestion, hideQuestionTitle } = useUneeqState()
  const defaultValue = isIOS
    ? dateFnsFormat(new Date(), 'yyyy-MM-dd')
    : new Date()
  const [date, setDate] = useState<string | Date>(defaultValue)
  const [isDateValid, setIsDateValid] = useState(true)
  const questionText = useMemo(() => stripSsml(mayaQuestion.question), [
    mayaQuestion
  ])

  useEffect(() => {
    if (mayaQuestion.value) {
      const isValidDate = !isNaN(Date.parse(mayaQuestion.value))

      if (isValidDate) {
        const formatedValue = isIOS
          ? dateFnsFormat(new Date(mayaQuestion.value), 'yyyy-MM-dd')
          : new Date(mayaQuestion.value)

        setDate(formatedValue)
      }
    }
  }, [mayaQuestion])

  const submitDateInput = (date: Date | null | string) => {
    if (date) {
      const formatedDate = getFormatedDate(date)
      const info = {
        type: 'response',
        questionId: mayaQuestion.id,
        response: formatedDate,
        label: formatedDate ? formatedDate : t('Transcript.skippedQuestion')
      }
      dispatch({ type: 'mayaMessage', payload: info })
      sendMessage(info)
    }
  }

  const submitOnEnter = (e: any) => {
    if (e.key === 'Enter') {
      submitDateInput(date)
    }
  }

  const input = () => {
    if (isIOS) {
      return (
        <Input
          value={date as string}
          onChange={e => setDate(e.target.value)}
          sx={styles.dateIOS}
          type="date"
        />
      )
    }

    return (
      <DayPickerInput
        value={date}
        formatDate={formatDate}
        onKeyUp={submitOnEnter}
        format={FORMAT}
        onDayChange={date =>
          date ? setIsDateValid(true) : setIsDateValid(false)
        }
        parseDate={parseDate}
        placeholder={`${dateFnsFormat(new Date(), FORMAT)}`}
        style={styles.dateInputContainer}
        inputProps={{
          style: styles.dateInput,
          readOnly: true
        }}
        overlayComponent={DatePickerOverlay}
        dayPickerProps={{
          onDayClick: setDate,
          fixedWeeks: true,
          month: date as Date,
          toMonth: new Date(),
          captionElement: ({ date, localeUtils }) => (
            <YearMonthForm
              date={date}
              localeUtils={localeUtils}
              onChange={setDate}
            />
          )
        }}
      />
    )
  }

  const disabledSubmitButton = !date || !isDateValid

  return (
    <Flex sx={styles.questionContainer}>
      <Flex sx={styles.topContainer}>
        {!hideQuestionTitle && <Text sx={styles.question}>{questionText}</Text>}
        <Text sx={styles.instructions}>{mayaQuestion.instruction}</Text>
      </Flex>
      <Flex
        sx={{
          ...styles.inputContainer,
          ...(!isDateValid ? styles.textInputError : {})
        }}
      >
        {input()}
        {mayaQuestion.optional && (
          <Button
            type="submit"
            onClick={() => submitDateInput(null)}
            sx={{ ...styles.button, mr: 3, fontWeight: 'normal' }}
            variant="whiteInverted"
          >
            {t('Question.skip')}
          </Button>
        )}
        <Button
          type="submit"
          disabled={disabledSubmitButton}
          onClick={() => submitDateInput(date)}
          sx={styles.button}
        >
          {t('Question.submit')}
        </Button>
      </Flex>
    </Flex>
  )
}

export default DateQuestion
