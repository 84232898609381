import React, { useContext } from 'react'
import { Button, Flex, Text } from 'rebass'
import { ReactComponent as ExclamationIcon } from './exclamation.svg'
import { styles as s } from './styles'
import { UneeqContext } from 'uneeq-react-core'
import { MayaOverlay } from '../MayaOverlay'
const styles = s.error

interface ErrorProps {
  message?: string
}
const Error: React.FC<ErrorProps> = ({
  message = `An unknown error ocurred`
}) => {
  const { dispatch } = useContext(UneeqContext)
  const clearError = () => dispatch({ type: 'clearError' })
  return (
    <MayaOverlay sx={styles.container}>
      <Flex sx={styles.warning}>
        <Flex>
          <Flex sx={styles.icon}>
            <ExclamationIcon />
          </Flex>
          <Text sx={styles.heading}>Error</Text>
        </Flex>
        <Text sx={styles.message}>{message}</Text>
        <Flex sx={styles.divider} />
        <Flex>
          <Button
            sx={styles.closeButton}
            variant="primary"
            onClick={clearError}
          >
            Close
          </Button>
        </Flex>
      </Flex>
    </MayaOverlay>
  )
}

export default Error
