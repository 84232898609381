import React, { useState } from 'react'
import styles from './styles'
import { trackHandler, useTranscript } from 'uneeq-react-core'
import { Button } from 'rebass'
import { ReactComponent as BackIcon } from './back.svg'
import { sendMessage } from '../../socket'

interface MayaBackButtonProps {
  onClick?: () => any
  sx?: any
}

const MayaBackButton = ({ onClick, sx = {} }: MayaBackButtonProps) => {
  const { transcript } = useTranscript()

  const back = () => {
    if (onClick) {
      onClick()
    }
    sendMessage({ type: 'back' })
  }
  if (transcript.length < 2) return null

  return (
    <Button
      sx={{ ...styles.container, ...sx }}
      variant="unstyled"
      onClick={trackHandler(back, 'back-btn')}
    >
      <BackIcon />
    </Button>
  )
}

export default MayaBackButton
