const styles = {
  questionsContainer: {
    label: 'questionsContainer',
    color: 'text',
    minHeight: 177,
    maxHeight: ['100vh', '100vh', '100vh', '100vh', 'auto', 'auto'],
    width: ['100%', '100%', '100%', '100%', 560, 560],
    backgroundColor: 'backgroundPrimary',
    borderRadius: ['unset', 'unset', 'unset', 'unset', 'card', 'card'],
    backdropFilter: 'blur(50px)',
    padding: [6, 6, 10, 10, 10, 10],
    flexDirection: 'column',
    zIndex: 5,
    position: 'relative'
  },
  questionsFooter: {
    alignItems: 'center',
    justifyContent: 'space-between',
    mt: 12
  },
  questionsFooterActions: {
    '& > *:not(:last-child)': {
      marginRight: 2
    }
  },
  questionContainer: {
    label: 'question',
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'column',
    minWidth: ['90%', '90%', '90%', '90%', 400, 400],
    position: 'relative'
  },
  questionAutocompleteContainer: {
    label: 'questionsContainer',
    flexDirection: 'column',
    color: 'text',
    width: ['100%', '100%', '100%', '100%', 560, 560],
    backgroundColor: 'backgroundPrimary',
    borderRadius: ['unset', 'unset', 'unset', 'unset', 'card', 'card'],
    backdropFilter: 'blur(50px)',
    padding: [6, 6, 10, 10, 10, 10],
    zIndex: 5,
    position: 'relative'
  },
  topContainer: {
    alignItems: 'flex-start',
    flexDirection: 'column',
    mb: 12
  },
  question: {
    fontSize: ['16px', '16px', '24px', '24px', '24px', '24px'],
    lineHeight: ['20px', '20px', '32px', '32px', '32px', '32px'],
    letterSpacing: '-0.01em'
  },
  instructions: {
    fontSize: ['12px', '12px', '16px', '16px', '16px', '16px'],
    lineHeight: ['16px', '16px', '24px', '24px', '24px', '24px'],
    letterSpacing: '-0.01em',
    opacity: 0.6
  },
  divider: {
    background: '#C4C4C4',
    opacity: 0.2,
    width: '100%',
    height: '1px',
    my: 8
  },
  inputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid',
    borderColor: 'backgroundSecondary',
    borderRadius: '4px',
    backgroundColor: 'backgroundSecondary',
    p: 2,
    color: 'text',
    fontSize: ['14px', '14px', '16px', '16px', '16px', '16px']
  },
  textInputError: {
    borderColor: '#ff0033'
  },
  textInput: {
    fontFamily: 'inherit',
    fontSize: ['14px', '14px', '16px', '16px', '16px', '16px'],
    lineHeight: '24px',
    color: 'text',
    border: 'none',
    letterSpacing: '-0.01em',
    width: '100%',
    ':focus': {
      outline: 'none'
    },
    '&::placeholder': {
      opacity: 0.7
    },
    p: 0,
    pl: 2
  },
  dateIOS: {
    fontFamily: 'inherit',
    fontSize: ['14px', '14px', '16px', '16px', '16px', '16px'],
    lineHeight: '24px',
    color: 'text',
    border: 'none',
    letterSpacing: '-0.01em',
    width: '100%',
    ':focus': {
      outline: 'none'
    },
    '&::placeholder': {
      opacity: 0.7
    }
  },
  dateInputContainer: {
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: '24px',
    color: 'text',
    width: '100%',
    ':focus': {
      outline: 'none'
    },
    '&::placeholder': {
      opacity: 0.7
    },
    padding: 0,
    paddingLeft: '8px'
  },
  dateInput: {
    background: 'transparent',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: '24px',
    color: 'inherit',
    border: 'none',
    letterSpacing: '-0.01em',
    width: '100%',
    outline: 'none',
    '&::placeholder': {
      opacity: 0.7
    }
  },
  datePickerForm: {
    width: '80%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& *:not(:last-child)': {
      mr: 3
    },
    '& *:last-child': {
      flex: 1
    }
  },
  datePickerSelect: {
    borderRadius: '4px',
    borderColor: 'backgroundSecondary'
  },
  selectContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid',
    borderColor: 'backgroundSecondary',
    borderRadius: '4px',
    backgroundColor: 'backgroundSecondary',
    p: 2,
    '& > div': {
      flex: 1,
      mr: 6
    }
  },
  multiSelectOptionsContainer: {
    label: 'multiSelectOptions',
    overflowY: 'auto',
    maxHeight: ['35vh', '35vh', '35vh', '35vh', '30vh', '30vh'],
    display: 'flex',
    pb: '6px',
    flexDirection: 'column',
    '& > *:not(:first-child)': {
      marginTop: '6px'
    }
  },
  widgetMultiSelectOptionsContainer: {
    maxHeight: ['20vh', '20vh', '20vh', '20vh', '25vh', '25vh']
  },
  countrySelect: {
    backgroundColor: 'transparent',
    border: 'none',
    fontFamily: 'inherit',
    p: 0,
    pl: 2,
    width: '100%',
    textAlign: 'left',
    color: 'text',
    ':focus': {
      border: 'none',
      outline: 'none'
    }
  },
  selectQuestionContainer: {
    flexDirection: 'column',
    flex: 1,
    zIndex: 4
  },
  checkboxLabel: {
    width: 'auto',
    ml: 10
  },
  button: {
    px: '12px',
    py: '6px',
    // minHeight: 37,
    fontSize: ['14px', '14px', '16px', '16px', '16px', '16px'],
    lineHeight: ['17px', '17px', '19px', '19px', '19px', '19px'],
    fontWeight: 700,
    minWidth: 'unset',
    borderRadius: 'buttonBig'
  },
  textAreaButton: {
    alignSelf: 'flex-end'
  },
  multiSelectSubmitButton: {
    width: 'fit-content',
    py: 2,
    px: 7,
    ml: 0,
    mt: 12,
    display: 'flex',
    alignSelf: 'flex-end'
  },
  selectButton: {
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: 'backgroundSecondary',
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingLeft: '16px',
    paddingRight: '16px',
    width: '100%',
    textAlign: 'left',
    color: 'text',
    cursor: 'pointer',
    whiteSpace: 'pre-wrap',
    ':focus': {
      outline: 'none',
      backgroundColor: 'transparent'
    }
  },
  autocompleteItem: {
    borderRadius: '4px',
    fontSize: '14px'
  },
  autocompleteContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
    '& li:not(:first-child)': {
      marginTop: '6px'
    },
    '& li:last-child': {
      marginBottom: '32px'
    }
  },
  autocompleteMenu: {
    maxHeight: ['unset', 'unset', 300, 300, 300],
    height: ['100%', '100%', 'unset', 'unset', 'unset'],
    overflowY: 'auto',
    padding: 0,
    listStyle: 'none',
    position: 'relative',
    margin: 0,
    width: '100%',
    flex: 1
  },
  autocompleteCombobox: {
    display: 'inline-block',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid',
    borderColor: 'backgroundSecondary',
    borderRadius: '4px',
    backgroundColor: 'backgroundSecondary',
    p: 2
  },
  autocompleteInputContainer: {
    position: 'relative',
    flex: 1
  },
  autocompleteInputClearButton: {
    cursor: 'pointer',
    position: 'absolute',
    top: '50%',
    right: '16px',
    fontSize: '1rem',
    transform: 'translateY(-50%)',
    color: 'text',
    height: 16,
    '& svg': {
      minWidth: 16,
      height: 16,
      margin: 0,
      fill: 'text'
    }
  },
  arrowDownContainer: {
    position: 'absolute',
    bottom: 5,
    left: '50%',
    transform: 'translate(-50%)'
  },
  arrowDown: {
    animation:
      'shake-vertical 5s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both',
    '-webkit-animation':
      'shake-vertical 5s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both',
    border: 'none',
    color: 'black',
    background: 'transparent',
    '& svg': {
      height: '24px',
      width: '24px',
      fill: 'text'
    }
  }
}
export default styles
