import React, { useContext, useState, useRef, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  stripSsml,
  UneeqContext,
  useUneeqState,
  useIsSmallScreen
} from 'uneeq-react-core'
import { sendMessage } from '../../../socket'
import { Button, Flex, Text } from 'rebass'
import styles from '../styles'
import { Input } from '@rebass/forms'

const getInputMode = (type: 'number' | 'text' | 'email' | 'tel') => {
  switch (type) {
    case 'number':
      return 'numeric'
    case 'text':
      return 'text'
    case 'email':
      return 'email'
    case 'tel':
      return 'tel'
    default:
      return 'text'
  }
}

interface TextQuestionProps {
  type: 'number' | 'text' | 'email' | 'tel'
}

const TextQuestion = ({ type }: TextQuestionProps) => {
  const { dispatch } = useContext(UneeqContext)
  const { t } = useTranslation()
  const { mayaQuestion, hideQuestionTitle } = useUneeqState()

  const isSmallScreen = useIsSmallScreen()
  const [text, setText] = useState('')
  const [inputValid, setInputValid] = useState(true)
  const inputRef = useRef<HTMLElement>(null)
  const questionText = useMemo(() => stripSsml(mayaQuestion.question), [
    mayaQuestion
  ])

  // We clean it in this way because we want to keep the answer until the next question arrives.
  useEffect(() => {
    if (mayaQuestion.value) {
      setText(mayaQuestion.value)
    } else {
      setText('')
    }
  }, [mayaQuestion])

  const submitTextInput = (text: string) => {
    const info = {
      type: 'response',
      questionId: mayaQuestion.id,
      response: text,
      label: text ? text : t('Transcript.skippedQuestion')
    }
    dispatch({ type: 'mayaMessage', payload: info })
    sendMessage(info)
  }

  const validateNumberInput = (value: string) => {
    setText(value)
    const numberValue = +value
    if (mayaQuestion.min || mayaQuestion.max) {
      setInputValid(
        numberValue >= Math.max(mayaQuestion.min, Number.NEGATIVE_INFINITY) &&
          numberValue <= Math.min(mayaQuestion.max, Number.POSITIVE_INFINITY)
      )
    }
  }

  const submitOnEnter = (e: any) => {
    if (e.key === 'Enter') {
      submitTextInput(text.toString())
    }
  }

  const disabledSubmitButton = !text || !inputValid

  const submitButton = () => {
    if (!mayaQuestion.optional || !disabledSubmitButton) {
      return (
        <Button
          type="submit"
          disabled={disabledSubmitButton}
          onClick={() => submitTextInput(text.toString())}
          sx={styles.button}
        >
          {t('Question.submit')}
        </Button>
      )
    }

    return (
      <Button
        type="submit"
        onClick={() => submitTextInput('')}
        sx={styles.button}
      >
        {t('Question.skip')}
      </Button>
    )
  }

  return (
    <Flex sx={styles.questionContainer}>
      <Flex sx={styles.topContainer}>
        {!hideQuestionTitle && <Text sx={styles.question}>{questionText}</Text>}
        <Text sx={styles.instructions}>{mayaQuestion.instruction}</Text>
      </Flex>
      <Flex
        sx={{
          ...styles.inputContainer,
          ...(!inputValid ? styles.textInputError : {})
        }}
      >
        <Input
          ref={inputRef}
          value={text}
          autoFocus={!isSmallScreen}
          onChange={e => validateNumberInput(e.target.value)}
          onKeyPress={submitOnEnter}
          type={type}
          inputMode={getInputMode(type)}
          min={mayaQuestion.min}
          max={mayaQuestion.max}
          placeholder={
            mayaQuestion.placeholder || t('Question.inputPlaceholder')
          }
          sx={styles.textInput}
        />
        {submitButton()}
      </Flex>
    </Flex>
  )
}

export default React.memo(TextQuestion)
