const mayaConfirmLeave = (state: any, action: any) => {
  switch (action.type) {
    case 'mayaOpenConfirmLeave':
      return { ...state, confirmLeaveOpen: true }
    case 'mayaCloseConfirmLeave':
      return { ...state, confirmLeaveOpen: false }
    default:
      return state
  }
}

export default mayaConfirmLeave
