const mayaExitSessionReducer = (config?: any) => (state: any, action: any) => {
  switch (action.type) {
    case 'mayaMessage':
      switch (action?.payload?.type) {
        case 'exit':
          return {
            ...state,
            mayaInformation: null,
            mayaQuestion: null,
            questionLoading: false,
            exitSession: true
          }
        default:
          return state
      }
    default:
      return state
  }
}

export default mayaExitSessionReducer
