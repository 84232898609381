import React from 'react'
import styles from '../styles'
import { Box } from 'rebass'
import { Label, Input } from '@rebass/forms'
import { ReactComponent as CurrentLocationIcon } from '../icons/current.svg'
import { ReactComponent as HomeIcon } from '../icons/home.svg'

export type UserLocation = 'home' | 'current'

interface RadioFormProps {
  userLocation: UserLocation
  setUserLocation: (val: UserLocation) => void
}
const RadioForm = ({ userLocation, setUserLocation }: RadioFormProps) => {
  return (
    <Box as="form" sx={styles.headerLocation}>
      <Label
        htmlFor="home"
        sx={{
          ...styles.radioButtonLabel,
          ...(userLocation === 'home' ? styles.radioButtonLabelSelected : {})
        }}
        onClick={() => setUserLocation('home')}
      >
        <Input
          type="radio"
          sx={{ display: 'none' }}
          value="home"
          name="location"
          id="home"
        />
        <HomeIcon />
      </Label>
      <Label
        htmlFor="current"
        sx={{
          ...styles.radioButtonLabel,
          ...(userLocation === 'current' ? styles.radioButtonLabelSelected : {})
        }}
        onClick={() => setUserLocation('current')}
      >
        <Input
          type="radio"
          sx={{ display: 'none' }}
          value="current"
          name="location"
          id="current"
        />
        <CurrentLocationIcon />
      </Label>
    </Box>
  )
}

export default RadioForm
