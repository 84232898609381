// @ts-nocheck
import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'

const widgetMode = process.env.REACT_APP_BUILD_MODE === 'widget'
if (widgetMode) {
  import('./createShadowRoot').then(() => {
    require('./index.css')

    // Get the shadow root
    const shadowRoot = document.getElementById('mayaChatWidget')!.shadowRoot

    // Create div element for react to render into
    const reactRoot = document.createElement('div')
    reactRoot.setAttribute('id', 'react-root')

    // Append react root to shadow root
    shadowRoot!.appendChild(reactRoot)

    const init = ({ apiKey, apiSecret, sid }) => {
      if (!apiKey) {
        console.error('No Maya API key')
        return
      }

      if (!apiSecret) {
        console.error('No Maya API Secret')
        return
      }

      const App = require('./widget/Widget').default
      return ReactDOM.render(
        <App mayaApiKey={apiKey} mayaApiSecret={apiSecret} mayaSid={sid} />,
        reactRoot
      )
    }

    window.MayaMD = {
      init
    }
  })
} else {
  require('./index.css')
  const App = require('./app/App').default
  ReactDOM.render(<App />, document.getElementById('root'))
  serviceWorker.register()
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
