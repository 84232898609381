import React, { createElement, useContext } from 'react'
import { Button, Flex, Heading, Image, Text, Link } from 'rebass'
import { useTranslation } from 'react-i18next'
// @ts-ignore
import marksy from 'marksy'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'
import styles from './styles'
import { sendMessage } from '../../socket'
import MayaBackButton from '../MayaBackButton/MayaBackButton'

const compile = marksy({
  elements: {
    a: ({ href, children, ...otherProps }: any) => {
      if (href.includes('maya-page://')) {
        return (
          <Text
            sx={styles.mayaPageLink}
            onClick={() =>
              sendMessage({
                type: 'infoPage',
                pageId: href.split('maya-page://')[1]
              })
            }
          >
            {children}
          </Text>
        )
      } else {
        return (
          <Link
            href={href}
            {...otherProps}
            target="_blank"
            rel="noopener noreferrer"
            sx={styles.anchor}
          >
            {children}
          </Link>
        )
      }
    },
    h1: ({ children }: any) => (
      <Heading sx={styles.heading}>{children}</Heading>
    ),
    h2: ({ children }: any) => (
      <Heading sx={styles.heading}>{children}</Heading>
    ),
    h3: ({ children }: any) => (
      <Heading sx={styles.heading}>{children}</Heading>
    ),
    h4: ({ children }: any) => (
      <Heading sx={styles.heading}>{children}</Heading>
    ),
    p: ({ children }: any) => <Text sx={styles.paragraph}>{children}</Text>,
    img({ src, alt }: any) {
      if (alt === 'progressbar') {
        const percentage = src.replace(/\D+/g, '')
        return (
          <Flex sx={styles.loading.barContainer}>
            <Flex sx={styles.loading.barInnerContainer}>
              <Flex
                sx={{
                  ...styles.loading.bar,
                  width: `${percentage}%`
                }}
              />
            </Flex>
          </Flex>
        )
      } else {
        return <Image src={src} alt={alt} />
      }
    }
  },

  createElement
})

const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

const downloadBase64 = (b64Data: string, contentType: string) => {
  const blob = b64toBlob(b64Data, contentType)
  const blobUrl = URL.createObjectURL(blob)

  window.open(blobUrl, '_blank')
}

type DownloadPdfButton = { label?: string; pdf?: string; name?: string }
const DownloadPdfButton = ({ label, pdf, name }: DownloadPdfButton) => {
  const { t } = useTranslation()
  if (!pdf) return null

  const buttonText = label ? label : t('Question.downloadPdfButton')

  return (
    <Button
      onClick={() => downloadBase64(pdf, 'application/pdf')}
      sx={{ ...styles.button, ...styles.multiSelectSubmitButton }}
    >
      {buttonText}
    </Button>
  )
}

interface ActionButtonProps {
  text: string
  action: string
  link?: string
  keepInfoOpen: boolean
}

const MayaInformation = () => {
  const { mayaInformation, hideInformation } = useUneeqState()
  const { dispatch } = useContext(UneeqContext)
  if (!mayaInformation?.markdown || hideInformation) return null

  const clearInformation = () => {
    clearInfoPage()
    sendMessage({ type: 'infoClosed', pageId: mayaInformation.pageId })
  }

  const sendAction = (action: string, keepInfoOpen: boolean) => {
    if (!keepInfoOpen) {
      clearInfoPage()
    }
    sendMessage({ type: 'button', action })
  }

  const clearInfoPage = () =>
    dispatch({ type: 'mayaMessage', payload: { type: 'clearInfoPage' } })

  const buttons = () => {
    return mayaInformation.buttons ? (
      mayaInformation.buttons.map(
        ({ text, action, keepInfoOpen, link }: ActionButtonProps) => {
          const handleClick = () => {
            if (link) {
              window.open(link, action)
              return sendAction(action, true)
            }

            sendAction(action, keepInfoOpen)
          }

          return (
            <Button
              type="submit"
              key={action}
              onClick={handleClick}
              sx={{ ...styles.button, ...styles.multiSelectSubmitButton }}
            >
              {text}
            </Button>
          )
        }
      )
    ) : mayaInformation.buttonText ? (
      <Button
        type="submit"
        onClick={clearInformation}
        sx={{ ...styles.button, ...styles.multiSelectSubmitButton }}
      >
        {mayaInformation.buttonText ? mayaInformation.buttonText : 'Okay'}
      </Button>
    ) : null
  }

  const compiled = compile(mayaInformation.markdown)
  return (
    <Flex sx={styles.informationContainer}>
      <Flex sx={styles.content}>{compiled.tree}</Flex>
      <Flex sx={styles.column}>
        <Flex sx={styles.divider} />
        <Flex sx={styles.actions}>
          {!mayaInformation.hideBack && (
            <MayaBackButton
              onClick={clearInfoPage}
              sx={{ display: 'flex', alignSelf: 'center', mr: 2 }}
            />
          )}
          <Flex sx={styles.buttonsContainer}>
            {buttons()}
            {
              <DownloadPdfButton
                pdf={mayaInformation.pdf?.base64}
                name={mayaInformation.pdf?.name}
                label={mayaInformation.pdf?.label}
              />
            }
          </Flex>
        </Flex>
      </Flex>{' '}
    </Flex>
  )
}

export default MayaInformation
