import { keyframes } from '@emotion/core'

const progressBarStripes = keyframes`
    0% {
      background-position: 1rem 0;
    }
    100% {
      background-position: 0 0;
    }
`

const styles = {
  informationContainer: {
    label: 'mayaInformation',
    color: 'text',
    minHeight: 329,
    maxHeight: [
      '50vh',
      '50vh',
      '50vh',
      '50vh',
      'calc(100% - 80px)',
      'calc(100% - 80px)'
    ],
    width: ['100%', '100%', '100%', '100%', 560, 560],
    backgroundColor: 'backgroundPrimary',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backdropFilter: 'blur(50px)',
    borderRadius: ['unset', 'unset', 'unset', 'unset', 'card', 'card'],
    padding: [6, 6, 10, 10, 10, 10],
    zIndex: 5,
    position: 'relative'
  },
  content: {
    flexDirection: 'column',
    maxHeight: '95%',
    overflow: 'auto'
  },
  column: {
    flexDirection: 'column'
  },
  divider: {
    background: '#C4C4C4',
    opacity: 0.2,
    width: '100%',
    height: '1px',
    my: 8
  },
  actions: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    overflowX: 'auto',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      mr: 2
    }
  },
  button: {
    px: 30,
    py: 5,
    minHeight: 37,
    fontWeight: 700,
    ml: 10,
    borderRadius: 'buttonBig'
  },
  multiSelectSubmitButton: {
    width: 'fit-content',
    py: 2,
    px: 7,
    ml: 0,
    minWidth: 'auto'
  },
  heading: {
    fontSize: [0, 0, 4, 4, 4, 4],
    lineHeight: '24px',
    fontWeight: 'bold',
    m: 0,
    p: 0
  },
  paragraph: {
    fontSize: [0, 0, 2, 2, 2, 2],
    lineHeight: ['20px', '20px', '24px', '24px', '24px', '24px'],
    mt: 2
  },
  mayaPageLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue'
  },
  anchor: {
    fontSize: ['12px', '12px', '16px', '16px', '16px', '16px'],
    lineHeight: ['16px', '16px', '24px', '24px', '24px', '24px'],
    letterSpacing: '-0.01em',
    opacity: 0.6,
    textDecoration: 'underline',
    color: 'text',
    cursor: 'pointer',
    ':link': {
      fontSize: ['12px', '12px', '16px', '16px', '16px', '16px'],
      lineHeight: ['16px', '16px', '24px', '24px', '24px', '24px'],
      letterSpacing: '-0.01em',
      opacity: 0.6,
      textDecoration: 'underline',
      color: 'text',
      cursor: 'pointer'
    },
    ':visited': {
      fontSize: ['12px', '12px', '16px', '16px', '16px', '16px'],
      lineHeight: ['16px', '16px', '24px', '24px', '24px', '24px'],
      letterSpacing: '-0.01em',
      opacity: 0.6,
      textDecoration: 'underline',
      color: 'text',
      cursor: 'pointer'
    }
  },
  loading: {
    barContainer: {
      width: ['90%', '90%', '80%', '80%', '60%', '60%'],
      justifyContent: 'flex-start',
      border: '1px solid transparent',
      borderColor: 'primary',
      borderRadius: '4px'
    },
    barInnerContainer: {
      width: '100%',
      borderRadius: '3px',
      backgroundColor: 'white',
      p: '1px'
    },
    bar: {
      height: [5, 5, 14, 14, 7, 7],
      animation: `${progressBarStripes} 1s linear infinite`,
      backgroundImage:
        'linear-gradient(45deg,hsla(0,0%,100%,.15) 25%,transparent 0,transparent 50%,hsla(0,0%,100%,.15) 0,hsla(0,0%,100%,.15) 75%,transparent 0,transparent)',
      backgroundColor: 'primary',
      transition: 'width .6s ease',
      backgroundSize: '1rem 1rem',
      borderRadius: '4px'
    }
  }
}

export default styles
