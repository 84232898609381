import React from 'react'
import { SxStyleProp } from 'rebass'
import { Overlay } from 'uneeq-react-ui'
import useWidgetContext from '../../app/hooks/useWidgetContext'

interface MayaOverlayProps {
  transparent?: boolean
  children: React.ReactNode
  sx?: SxStyleProp
}
const MayaOverlay = ({ children, sx, ...props }: MayaOverlayProps) => {
  const { widgetMode } = useWidgetContext()
  return (
    <Overlay
      {...props}
      sx={{ ...sx, ...(widgetMode ? { position: 'fixed' } : {}) }}
    >
      {children}
    </Overlay>
  )
}

export default MayaOverlay
