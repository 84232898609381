const mayaExitSessionMiddleware = (config?: any) => (
  state: any,
  action: any,
  uneeq: any,
  context: any
) => {
  if (action?.type === 'mayaMessage' && action?.payload?.type === 'exit') {
    const { endSession } = context
    config.endMayaSession()
    endSession()
  }
}

export default mayaExitSessionMiddleware
