import React, { useEffect, useState } from 'react'
import { Box } from 'rebass'
import { ThemeProvider } from 'emotion-theming'
import { useTranslation } from 'react-i18next'

import theme, { THEMES } from '../theme'
import Home from './Home'

import SessionTimedOut from './SessionTimedOut'
import DigitalHuman from './DigitalHuman'
import { getToken } from '../socket'
import ThemeSelector from './ThemeSelector/ThemeSelector'
import FatalError from '../customPlugins/MayaErrors/FatalError'

const backgroundStyle = {
  label: 'wrapper',
  width: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'right bottom',
  backgroundColor: '#004C9C',
  overflow: 'hidden',
  position: 'absolute',
  top: 0,
  left: 0
}

const getTheme = (queryTheme: string | null) => {
  if (!queryTheme) {
    return window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
      ? THEMES.DARK
      : THEMES.DEFAULT
  }

  if (queryTheme.toLowerCase() === 'dark') {
    return THEMES.DARK
  }
  return THEMES.DEFAULT
}

const App = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const embeddedApp = Boolean(queryParams.get('embedded'))
  const { i18n } = useTranslation()

  // For faster testing, skip straight to digitalHuman
  const [step, goTo] = useState('welcome')
  const [speak, setSpeak] = useState(true)
  const [headingMessage, setHeadingMessage] = useState('')
  const [logo, setLogo] = useState('')
  const [disableDH, setDisableDH] = useState(true)
  const [initError, setInitError] = useState('')
  const [currentLanguage, setCurrentLanguage] = useState('')
  const [personaId, setPersonaId] = useState(
    process.env.REACT_APP_UNEEQ_PERSONA_ID || ''
  )

  const restart = () => goTo('welcome')

  // @ts-ignore
  var viewport = window.visualViewport
  const [height, setHeight] = useState(viewport?.height)

  const [activeTheme, setActiveTheme] = useState(THEMES.DARK)

  useEffect(() => {
    if (currentLanguage) {
      i18n.changeLanguage(currentLanguage)
    }
  }, [currentLanguage, i18n])

  useEffect(() => {
    viewport?.addEventListener('resize', () => {
      step === 'digitalHuman' && setHeight(viewport.height)
    })
  }, [step, viewport])

  useEffect(() => {
    const scrollToTop = () => {
      if (step === 'digitalHuman') {
        document.documentElement.scrollTop = 0
      }
    }
    scrollToTop()

    // @ts-ignore
    window.visualViewport?.addEventListener('scrollEnd', scrollToTop)
    // @ts-ignore
    return window.visualViewport?.removeEventListener('scrollEnd', scrollToTop)
  })

  getToken()
    .then(
      ({ message, disableDigitalHuman, logo, title, i18n: i18nProps }: any) => {
        const headingMessage = message || ' '
        setHeadingMessage(headingMessage)
        setDisableDH(disableDigitalHuman)
        setLogo(logo)
        if (title && !embeddedApp) {
          document.title = title
        }

        if (i18nProps) {
          i18n.addResourceBundle(
            i18nProps.key,
            'translation',
            i18nProps.translation
          )
          setCurrentLanguage(i18nProps.key)
          setPersonaId(i18nProps.personaId)
        }
      }
    )
    .catch(({ message }: any) => {
      setInitError(message)
    })

  return (
    <ThemeProvider theme={theme(activeTheme, embeddedApp)}>
      <Box
        sx={{
          ...backgroundStyle,
          height: height || '100%',
          overflow: step === 'welcome' ? 'visible' : 'hidden'
        }}
      >
        {initError && (
          <FatalError
            errorTitle="Something went wrong"
            errorMessage={initError}
            clearError={() => window.location.reload()}
            buttonText="Try again"
          ></FatalError>
        )}
        {step === 'digitalHuman' ? (
          <DigitalHuman
            speak={speak}
            onTimedOut={() => goTo('timed-out')}
            restart={restart}
            onSessionEnded={() => goTo('restart')}
            embeddedMode={embeddedApp}
            personaId={personaId}
          />
        ) : step === 'timed-out' ? (
          <SessionTimedOut restart={restart} />
        ) : (
          <Home
            embeddedMode={embeddedApp}
            startSession={speak => {
              setSpeak(speak)
              goTo('digitalHuman')
            }}
            restart={() => goTo('restart')}
            headingMessage={headingMessage}
            disableDigitalHuman={disableDH}
            logo={logo}
          />
        )}
      </Box>
    </ThemeProvider>
  )
}

export default App
