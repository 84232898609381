import RobustWebSocket from 'robust-websocket'

console.log(process.env.REACT_APP_MAYA_WEBSOCKET)
export const ws = new RobustWebSocket(process.env.REACT_APP_MAYA_WEBSOCKET)

let tokenPromise
export const getToken = (mayaApiKey, mayaApiSecret, mayaSid) => {
  let width = window.innerWidth
  let height = window.innerHeight
  if (window.visualViewport) {
    width = window.visualViewport.width
    height = window.visualViewport.height
  }
  const language = navigator.language || navigator.userLanguage

  if (!tokenPromise) {
    tokenPromise = new Promise((resolve, reject) => {
      let session
      let hideQuestion
      let disableDigitalHuman
      let title
      let headingMessage
      let logo
      let loadingTips
      let i18n
      // listen for  session & token
      const listener = event => {
        try {
          const message = JSON.parse(event.data)
          if (message.type === 'init') {
            session = message.session
            hideQuestion = message.hideQuestion
            disableDigitalHuman = message.disableDigitalHuman
            title = message.title
            headingMessage = message.message
            logo = message.logo
            loadingTips = message.loadingTips || []
            i18n = message.i18n
            // Request token
            ws.send(JSON.stringify({ type: 'token', session }))
          } else if (message.type === 'token') {
            // Stop listening
            ws.removeEventListener('message', listener)
            resolve({
              session,
              token: message.token,
              hideQuestion,
              disableDigitalHuman,
              title,
              message: headingMessage,
              logo,
              loadingTips,
              i18n
            })
          } else if (message.type === 'error') {
            ws.removeEventListener('message', listener)
            console.log({ message })
            reject({ message: message.message })
          }
        } catch (error) {
          console.error(error)
        }
      }
      ws.addEventListener('message', listener)

      // Init when connected
      const init = () => {
        const queryParams = new URLSearchParams(window.location.search)
        const sid = queryParams.get('sid')

        const session = sid
          ? { session: sid }
          : mayaSid
          ? { session: mayaSid }
          : {}

        ws.send(
          JSON.stringify({
            type: 'init',
            apiKey: mayaApiKey || process.env.REACT_APP_MAYA_API_KEY,
            apiSecret: mayaApiSecret || process.env.REACT_APP_MAYA_API_SECRET,
            width,
            height,
            domain: process.env.REACT_APP_DOMAIN || window.location.hostname,
            language,
            ...session
          })
        )
        // We don't want to emit init after a reconnection.
        ws.removeEventListener('open', init)
      }
      if (1 === ws.readyState) {
        init()
      } else {
        ws.addEventListener('open', init)
      }
    })
  }

  return tokenPromise
}

export const endSession = () => {
  // Send some message to maya?
  tokenPromise = null
}

export const sendMessage = async request => {
  const { session } = await getToken()
  ws.send(JSON.stringify({ session, ...request }))
}
