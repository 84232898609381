import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { sendMessage } from '../../socket'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'
import { Box, Text, Flex } from 'rebass'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'
import { Hospital } from './Hospital'
import RadioForm, { UserLocation } from './blocks/RadioForm'
import MayaCloseButton from '../MayaCloseButton/MayaCloseButton'

import styles from './styles'
import hospitalMarker from './icons/marker.svg'
import hospitalSelectedMarker from './icons/marker-selected.svg'
import locationMarker from './icons/location.svg'
import HospitalCarousel from './blocks/Carousel'

declare const google: any

const getPosition = (h: Hospital | null) => {
  if (!h) return {}
  return {
    lat: h.latitude,
    lng: h.longitude
  }
}

type Position = { lat: number; lng: number }

type MapInfo = {
  userLocations: Record<UserLocation, Position>
  hospitals: Hospital[]
}

const MayaMap = React.memo(() => {
  const { dispatch } = useContext(UneeqContext)
  const { t } = useTranslation()
  const {
    showMap,
    mapInfo
  }: { showMap: boolean; mapInfo: MapInfo } = useUneeqState()
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''
  })

  const [markerSelected, setMarkerSelected] = useState<Hospital | null>(null)
  const [userLocation, setUserLocation] = useState<UserLocation>('home')
  const [mapInited, setMapInited] = useState(false)
  const [map, setMap] = useState<any>(null)

  useEffect(() => {
    if (mapInfo?.hospitals && !markerSelected) {
      const hospital = mapInfo?.hospitals[0]
      setMarkerSelected(hospital)
      if (map) {
        map.setCenter({ lat: hospital.latitude, lng: hospital.longitude })
      }
    }
  }, [mapInfo, markerSelected, map])

  const onLoad = React.useCallback(map => {
    const bounds = new google.maps.LatLngBounds()
    map.fitBounds(bounds)
    setMap(map)
  }, [])

  const centerMapIntoHospital = (h: Hospital) => {
    const position = getPosition(h)
    if (!position.lat || !position.lng) return

    map.setCenter({ lat: position.lat - 0.007, lng: position.lng })
    map.setZoom(15)
  }

  const onUnmount = React.useCallback(map => {
    setMap(null)
  }, [])

  const handleMarkerSelection = (hospital: Hospital) => {
    setMarkerSelected(hospital)
    centerMapIntoHospital(hospital)
  }

  const onTilesLoaded = () => {
    if (map && !mapInited) {
      centerMapIntoHospital(mapInfo?.hospitals[0])
      setMapInited(true)
    }
  }

  if (!mapInfo || !mapInfo.hospitals) {
    return null
  }

  return showMap && isLoaded ? (
    <Box sx={styles.grid}>
      <Box sx={styles.header}>
        <Flex sx={styles.headerInfo}>
          <MayaCloseButton
            onClick={() => {
              dispatch({ type: 'clearMap' })
              sendMessage({ type: 'mapClosed' })
            }}
          />
          <Text>
            {t('Map.hospitalsNear', {
              hospitalsQuantity: mapInfo?.hospitals?.length ?? 0
            })}
          </Text>
        </Flex>
        <RadioForm
          userLocation={userLocation}
          setUserLocation={setUserLocation}
        />
      </Box>
      <GoogleMap
        mapContainerStyle={styles.container}
        center={getPosition(markerSelected)}
        zoom={15}
        onLoad={onLoad}
        onTilesLoaded={onTilesLoaded}
        onUnmount={onUnmount}
        options={{
          disableDefaultUI: true,
          center: getPosition(mapInfo?.hospitals[0]),
          styles: [
            {
              stylers: [
                {
                  hue: '#ff1a00'
                },
                {
                  invert_lightness: true
                },
                {
                  saturation: -100
                },
                {
                  lightness: 33
                },
                {
                  gamma: 0.5
                }
              ]
            },
            {
              featureType: 'water',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#2D333C'
                }
              ]
            }
          ]
        }}
      >
        <Marker
          animation="DROP"
          position={mapInfo?.userLocations?.[userLocation]}
          icon={{
            url: locationMarker,
            anchor: new google.maps.Point(5, 58)
          }}
        />
        {mapInfo?.hospitals.map(hospital => (
          <Marker
            animation="DROP"
            position={getPosition(hospital)}
            icon={{
              url:
                markerSelected?.key === hospital.key
                  ? hospitalSelectedMarker
                  : hospitalMarker,
              anchor: new google.maps.Point(0, 0)
            }}
            key={hospital.key}
            onClick={() => setMarkerSelected(hospital)}
          />
        ))}
      </GoogleMap>
      <Box sx={styles.hospitalsList}>
        {markerSelected ? (
          <HospitalCarousel
            selected={markerSelected}
            setSelected={handleMarkerSelection}
            hospitals={mapInfo?.hospitals}
          />
        ) : null}
      </Box>
    </Box>
  ) : null
})

export default MayaMap
