import { sendMessage } from '../../socket'

const sessionIDMiddleware = (config?: any) => (
  state: any,
  action: any,
  uneeq: any
) => {
  if (action?.type === 'uneeqMessageSessionLive') {
    sendMessage({ type: 'sessionId', sessionId: uneeq.sessionId })
  }
}

export default sessionIDMiddleware
